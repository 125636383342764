<template>
	<div></div>
</template>
<script>

import { doctorInfoInWechat, getDoctorUserInfo } from '@/request/api/doctor-withdraw';
export default {
	mounted () {
		this.getParam();
	},
	methods: {
		getUser () {
			getDoctorUserInfo().then(data => {
				if (data && data === 'retry') {
					this.getUser();
				} else if (data) {
					window.sessionStorage.setItem('userInfo', JSON.stringify(data));
					this.getDoctorInfo();
				}
			});
		},
		getDoctorInfo () {
			const user = JSON.parse(window.sessionStorage.getItem('userInfo'));
			if (user.id) {
				doctorInfoInWechat({ userId: user.id }).then(data => {
					if (data && data === 'retry') {
						this.getDoctorInfo();
					} else if (data) {
						// console.log('doctorInfo=======', data)
						data.isDoctor = true;
						window.sessionStorage.setItem('doctorInfo', JSON.stringify(data));
						if (data.realName) {
							this.$root.replace('doctor-withdraw');
						} else {
							this.$root.replace('/complete-doctor');
						}
					} else {
						window.sessionStorage.setItem('doctorInfo', JSON.stringify({ isDoctor: false }));
						// window.localStorage.setItem('backUrl', '');
						this.$root.replace('/complete-doctor');
					}
				});
			} else {
				this.$root.replace('/complete-doctor');
			}
		},
		getParam () {
			const param = this.$route.query;
			if (param.accessToken) {
				window.sessionStorage.setItem('loginInfo', JSON.stringify(param));
				this.getUser();
			} else if (param.openid) {
				this.$root.go('/login-doctor', { openid: param.openid });
			} else {
				this.$toast('network error');
			}
		}
	}
};
</script>